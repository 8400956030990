<template>
    <q-card-section>
        <div class="col">
            <div class="row justify-center">
                <q-skeleton
                    animation="blink"
                    width="60%"
                    height="25px"
                />
            </div>
            <div class="row justify-center q-mt-sm">
                <q-skeleton
                    animation="blink"
                    width="20%"
                    height="25px"
                />
            </div>
            <div class="row justify-center q-mt-lg">
                <q-skeleton
                    animation="blink"
                    width="100%"
                    height="25px"
                />
            </div>
            <div class="row justify-center q-mt-sm">
                <q-skeleton
                    animation="blink"
                    width="100%"
                    height="25px"
                />
            </div>
            <div class="row justify-center q-mt-sm">
                <q-skeleton
                    animation="blink"
                    width="100%"
                    height="25px"
                />
            </div>
            <div class="row justify-center q-mt-sm">
                <q-skeleton
                    animation="blink"
                    width="100%"
                    height="25px"
                />
            </div>
            <div class="row justify-center q-mt-sm">
                <q-skeleton
                    animation="blink"
                    width="100%"
                    height="25px"
                />
            </div>
            <div class="row justify-center q-mt-sm">
                <q-skeleton
                    animation="blink"
                    width="100%"
                    height="25px"
                />
            </div>
            <div class="row justify-center q-mt-sm">
                <q-skeleton
                    animation="blink"
                    width="100%"
                    height="25px"
                />
            </div>
            <div class="row justify-center q-mt-sm">
                <q-skeleton
                    animation="blink"
                    width="100%"
                    height="25px"
                />
            </div>
            <div class="row justify-center q-mt-sm">
                <q-skeleton
                    animation="blink"
                    width="100%"
                    height="25px"
                />
            </div>
            <div class="row justify-center q-mt-sm">
                <q-skeleton
                    animation="blink"
                    width="100%"
                    height="25px"
                />
            </div>
        </div>
    </q-card-section>
</template>

<script>
export default {
    name: 'QSkeletonLoading'
}
</script>

<style>

</style>

import ServicesAuth from './servicesAuth'

class ParametrosServices extends ServicesAuth {
    constructor() {
        super({
            url: '/parametros',
            config: {
                // Configurações/Params genéricas para todos os métodos desta classe
                baseURL: process.env.VUE_APP_API_CREDITO_URL,
                headers: {
                    Authorization: `JWT ${process.env.VUE_APP_API_CREDITO_TOKEN}`
                },
                timeout: 60 * 1000
            }
        })
    }

    getParametro(parametro) {
        return this.axios.get(`${this.url}?nome=${parametro}`)
    }
}

export default new ParametrosServices()

<template>
    <q-card
        :style="estilo"
        class="col scroll bg-white text-black"
        bordered
        flat
    >
        <q-card-section v-if="loading">
            <q-skeleton-loading />
        </q-card-section>
        <q-card-section
            v-else
            v-html="contrato"
        />
    </q-card>
</template>

<script>

import ParametrosService from '@/services/parametros'
import QSkeletonLoading from '@/components/shared/QSkeletonLoading'
import MixinMessages from '@/mixins/mixinMessages'

export default {
    name: 'QContrato',

    components: {
        QSkeletonLoading
    },

    mixins: [
        MixinMessages
    ],

    props: {
        estilo: {
            type: String,
            default: null
        }
    },

    data() {
        return {
            contrato: null,
            empresa: {},
            loading: true
        }
    },

    mounted() {
        this.$_getContrato()
    },

    methods: {
        $_getContrato() {
            ParametrosService.getParametro('HTML_CONTRATO')
                .then(({ data }) => {
                    this.contrato = data[0].valor
                    this.loading = false
                })
                .catch(e => this.errorMessage({
                    title: 'Ops, algo de errado aconteceu!',
                    message: 'Erro ao buscar o termo',
                    error: e
                }))
        }
    }
}
</script>

<style>

</style>

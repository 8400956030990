<template>
    <div class="row justify-center">
        <div
            :class="`row bg-${$q.dark.isActive ? 'dark' : 'white'}`"
            style="width: 100%; max-width: 1200px;"
        >
            <div class="col full-height">
                <div
                    class="row q-layout-padding"
                    :style="`height: 90%`"
                >
                    <q-contrato-aceite />
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import QContratoAceite from '@/components/shared/QContratoAceite'

export default {
    name: 'QContrato',

    components: {
        QContratoAceite
    }
}
</script>

<style>

</style>
